// JavaScript Document

// Scripts written by Jacob Bearce @ Weblinx, Inc.

import NProgress from "nprogress";
import noUiSlider from "nouislider";
import wNumb from "wnumb";

const CONTAINER  = document.querySelector(".ia-activity-filter");
const TOGGLE     = CONTAINER ? CONTAINER.querySelector(".ia-activity-filter__toggle") : false;
const FORM       = CONTAINER ? CONTAINER.querySelector(".ia-activity-filter__inner") : false;
const RANGE      = CONTAINER ? CONTAINER.querySelector(".ia-toggle-group__noUi-target") : false;
const TERMS      = CONTAINER ? CONTAINER.querySelectorAll("[name^=term_") : false;
const AGE_FROM   = CONTAINER ? CONTAINER.querySelector("[name=age_from]") : false;
const AGE_TO     = CONTAINER ? CONTAINER.querySelector("[name=age_to]") : false;
const DOWS       = CONTAINER ? CONTAINER.querySelectorAll("[name='dow[]']") : false;
const INPUT      = CONTAINER ? CONTAINER.querySelector("#ia-activity-filter_search-input") : false;
const RESET      = CONTAINER ? CONTAINER.querySelector(".ia-activity-filter__reset-button") : false;
const TARGET     = document.getElementById("ia-activity-list");

const SLIDER = RANGE && AGE_FROM && AGE_TO ? noUiSlider.create(RANGE, {
    connect: true,
    range: {
        "min": activitywerx_l10n.age_limits.min,
        [`${(activitywerx_l10n.age_limits.adult -  activitywerx_l10n.age_limits.min) / ((activitywerx_l10n.age_limits.adult + 1) -  activitywerx_l10n.age_limits.min) * 100}%`]: [activitywerx_l10n.age_limits.adult, activitywerx_l10n.age_limits.senior],
        "max": activitywerx_l10n.age_limits.senior
    },
    start: [AGE_FROM.value ? AGE_FROM.value : activitywerx_l10n.age_limits.min, AGE_TO.value ? AGE_TO.value : activitywerx_l10n.age_limits.senior],
    step: 1,
    tooltips: wNumb({
        decimals: 0,
        edit: (value) => {
            if (parseInt(value) === activitywerx_l10n.age_limits.adult) {
                return `${activitywerx_l10n.age_limits.adult}+`;
            } else if (parseInt(value) === activitywerx_l10n.age_limits.senior) {
                return `${activitywerx_l10n.age_limits.senior}+`;
            }

            return value;
        },
    }),
}) : false;

/**
 * Filter the form
 */
const FILTER = () => {
    CONTAINER.classList.add("ia-is-loading");

    /**
     * Configure NProgress to display in the parent content block
     */
    NProgress.configure({
        parent: "#ia-activity-list",
    });

    /**
     * Start NProgress
     */
    NProgress.start();

    /**
     * Increment NProgress every 500ms
     */
    const TIMER = setTimeout(() => {
        NProgress.inc();
    }, 500);

    /**
     * Identify values
     */
    const DOW = [];
    const Q = INPUT.value;

    /**
     * Convert DOWs in to required format
     */
    DOWS.forEach((dow) => {
        if (dow.checked) {
            DOW.push(dow.value);
        }
    });

    /**
     * Construct query parameters
     */
    const PARAMS = new URLSearchParams({
        action: "activity_list",
        age_minimum: AGE_FROM.value,
        age_maximum: parseInt(AGE_TO.value) === activitywerx_l10n.age_limits.adult + 1 ? activitywerx_l10n.age_limits.senior : AGE_TO.value,
        dow: JSON.stringify(DOW),
        terms: JSON.stringify(Array.from(TERMS).filter(input => input.checked || input.type === "hidden").map(input => ({ name: input.name, value: input.value }))),
        q: Q,
    });

    /**
     * Submit request
     */
    fetch(`${activitywerx_l10n.ajax_url}?${PARAMS.toString()}`)
        .then((response) => {
            return response.text();
        })
        .then((html) => {
            /**
             * Display result
             */
            TARGET.innerHTML = html;

            /**
             * Stop NProgress increments
             */
            clearInterval(TIMER);

            /**
             * End the NProgress
             */
            NProgress.done();

            /**
             * Remove the "loading" marker
             */
            CONTAINER.classList.remove("ia-is-loading");
        })
        .catch((error) => {
            console.log(error);
        });
};

/**
 * Handle accordion for mobile
 */
if (CONTAINER && TOGGLE) {
    TOGGLE.addEventListener("click", (e) => {
        e.preventDefault();

        CONTAINER.classList.toggle("ia-is-active");
    }, { passive: false });
}

/**
 * Reset the form when clicked
 */
if (RESET && FORM) {
    RESET.addEventListener("click", (e) => {
        e.preventDefault();

        /**
         * Reset terms
         */
        if (TERMS && TERMS.length > 0) {
            TERMS.forEach((term) => {
                term.checked = false;
            });
        }

        /**
         * Reset age range
         */
        if (SLIDER) {
            SLIDER.reset();
        }

        /**
         * Uncheck and disable all DOWs
         */
        if (DOWS && DOWS.length > 0) {
            DOWS.forEach((dow) => {
                dow.checked = true;
            });
        }

        /**
         * Clear and disable input
         */
        if (INPUT) {
            INPUT.value = "";
        }

        /**
         * Apply the filters
         */
        FILTER();
    }, { passive: false });
}

/**
 * Filter the form when the page loads, and when the form is submitted
 */
if (FORM) {
    document.addEventListener("DOMContentLoaded", () => {
        FILTER();
    });

    FORM.addEventListener("submit", (e) => {
        e.preventDefault();
        FILTER();
    });
}

/**
 * Filter the form when a term changes
 */
if (TERMS && TERMS.length > 0) {
    TERMS.forEach((term) => {
        term.addEventListener("change", () => {
            FILTER();
        });
    });
}

/**
 * Filter the form when the age range is changed
 */
if (SLIDER) {
    SLIDER.on("set", () => {
        /**
         * Get the values
         */
        const DATA = SLIDER.get();

        /**
         * Update hidden inputs
         */
        AGE_FROM.value = parseInt(DATA[0]);
        AGE_TO.value   = parseInt(DATA[1]);

        /**
         * Run the filter
         */
        FILTER();
    });
}

/**
 * Filter the form when a day-of-the-week is clicked
 */
if (DOWS.length > 0) {
    DOWS.forEach((dow) => {
        dow.addEventListener("click", () => {
            FILTER();
        });
    });
}
